<template>
  <ion-item>
      <ion-label>{{item.actividad}}</ion-label>
      <ion-button color="light" @click="viewActividad(item.id)">Ver Actividad</ion-button>
  </ion-item>
</template>
<script>
import {IonItem, IonLabel, IonButton} from '@ionic/vue';
import { mapActions } from 'vuex';
export default {
    name: 'item-list-item',
    props:['item'],
    components: {
        IonItem,
        IonLabel,
        IonButton
    },    
    methods:{
        ...mapActions('actividad',['retrieveActividad']),
        async viewActividad(id){
            await this.retrieveActividad(id);
            this.$router.push('/actividad/'+ this.item.actividad);
        }
    }
}
</script>